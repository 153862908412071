import { useState, useEffect, useContext } from 'react'
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  IdToken,
  User as AuthUser,
} from '@auth0/auth0-spa-js'

import AuthContext from '../utils/authContext'

interface AuthProviderProps {
  children: any
  onRedirectCallback: (...args: any) => void
  initOptions: Auth0ClientOptions
}

export const isBrowser = () => typeof window !== `undefined`
export const useAuth0 = () => useContext(AuthContext)
const DEFAULT_REDIRECT_CALLBACK = () =>
  isBrowser() &&
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<AuthUser | undefined>({})
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [authLoading, setAuthLoading] = useState(true)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(
        initOptions as unknown as Auth0ClientOptions,
      )
      setAuth0(auth0FromHook)

      if (
        isBrowser() &&
        window.location.search.includes(`code=`) &&
        window.location.search.includes(`state=`)
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setAuthLoading(false)
    }
    initAuth0()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        authLoading,
        getIdTokenClaims: (...p) =>
          auth0Client?.getIdTokenClaims(...p) as Promise<IdToken>,
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        logout: (...p) => auth0Client?.logout(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
