import React from 'react'
import { navigate } from 'gatsby'

import { Auth0Provider, isBrowser } from './src/services/auth'

export const wrapRootElement = ({ element }) => {
  const onRedirectCallback = (appState) => {
    navigate(
      appState && appState.targetUrl
        ? appState.targetUrl
        : isBrowser()
        ? window.location.pathname
        : `/`,
    )
  }
  const audience = process.env.GATSBY_AUTH0_AUDIENCE

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      client_id={process.env.GATSBY_AUTH0_CLIENTID}
      redirect_uri={process.env.GATSBY_AUTH0_CALLBACK}
      {...(audience && { audience })}
      scope={`openid email profile`}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {element}
    </Auth0Provider>
  )
}
