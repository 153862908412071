import { createContext } from 'react'
import {
  RedirectLoginOptions,
  User as AuthUser,
  LogoutOptions,
  GetIdTokenClaimsOptions,
  IdToken,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js'

interface IAuthContext {
  isAuthenticated: boolean
  user: AuthUser | undefined
  authLoading: boolean
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions,
  ) => Promise<IdToken> | undefined
  loginWithRedirect: (
    options?: RedirectLoginOptions,
  ) => Promise<void> | undefined
  getTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string> | undefined
  logout: (options?: LogoutOptions) => Promise<void> | void
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  user: {},
  authLoading: false,
  getIdTokenClaims: () => Promise.resolve({} as IdToken),
  loginWithRedirect: () => Promise.resolve(),
  getTokenSilently: () => Promise.resolve(``),
  logout: () => Promise.resolve(),
})

export default AuthContext
